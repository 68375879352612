<template>
  <form
    id="piercing-info"
    action
    data-vv-scope="piercing-info-form"
    @submit.prevent="validateForm('piercing-info-form')"
  >
    <v-row justify="center">
      <v-col cols="12">
        <ServiceSelector @selected="changeBodyPart" />
      </v-col>
      <v-col cols="12" md="6" class="pt-3" v-if="change">
        <FaceSelectorPiercings
          @validated="selector = true"
          @body="
            change = false;
            customer.body_part = null;
          "
          :change="change"
          ref="body_selector"
        />
      </v-col>
      <v-col cols="12" md="6" class="pt-3" v-else>
        <BodySelectorPiercings
          @validated="selector = true"
          @head="
            change = true;
            customer.body_part = null;
          "
          :change="change"
          ref="body_selector"
        />
      </v-col>

      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="6">
            <label class="text-no-wrap">{{ $t("n_customers") }}</label>
            <v-select
              :items="range()(1, 5)"
              outlined
              dense
              hide-details
              v-model="n_customers"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <label>{{ $t("appointments.description") }}</label>
            <v-textarea
              outlined
              v-model="customer.description"
              auto-grow
              rows="1"
              hide-details
              style="border-radius: 10px"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div class="pa-3 text-right">
      <v-btn
        color="primary"
        v-if="!$route.params.tattooer_id || $route.params.pre"
        @click="
          $route.params.pre && $route.params.tattooer_id
            ? $router.push({
                name: 'customer_inscription_studio_pre',
                params: {
                  token: $route.params.token,
                  back: true,
                },
              })
            : $emit('back')
        "
        style="height: 30px; width: 115px"
        outlined
      >
        <v-icon>mdi-arrow-left</v-icon>
        {{ $t("back") }}
      </v-btn>
      <v-btn
        color="primary"
        type="submit"
        style="height: 30px"
        elevation="0"
        class="ml-2"
      >
        {{ $t("next") }}
        <v-icon>mdi-arrow-right</v-icon>
      </v-btn>
    </div>
  </form>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "PiercingInfo",
  data() {
    return {
      change: false,
      boxes: [],
      selector: false,
    };
  },
  watch: {
    serviceSelected: {
      handler() {
        console.log("service changed");
      },
      deep: true,
    },
  },
  mounted() {
    setTimeout(() => {
      this.fetchBoxes();
    }, 1000);
  },
  methods: {
    changeBodyPart(service) {
      console.log(service);
      if (service.body_part != "all") {
        this.customer.body_part = `${service.body_part}.${service.sub_body_part}`;
        if (service.body_part == "bodyPiercing") this.change = false;
        else this.change = true;
      } else {
        //this.customer.body_part = null;
      }
    },
    ...mapGetters("app", ["range"]),
    async validateForm(scope) {
      //console.log("validateForm");
      this.errors.clear();

      this.$validator.validateAll(scope).then((result) => {
        //console.log("validateTattooerForm", result, this.selector);
        if (result && this.selector) {
          this.$emit("validated", this.studio);
        }
      });
    },
    ...mapActions("boxes", ["getBoxes"]),
    fetchBoxes() {
      if (this.$store.getters["auth/isLoggedIn"])
        this.getBoxes({
          pagination: this.options,
          filters: { ...this.filters, studio_id: this.customer.studio.id },
        }).then((boxes) => {
          this.boxes = boxes.data;
        });
    },
  },
  components: {
    FaceSelectorPiercings: () =>
      import("@/components/ui/FaceSelectorPiercings"),
    BodySelectorPiercings: () =>
      import("@/components/ui/BodySelectorPiercings"),
    ServiceSelector: () => import("@/components/inscription/ServiceSelector"),
  },
  computed: {
    ...mapState("inscriptions", [
      "tattooerState",
      "customerState",
      "serviceSelected",
    ]),

    tattooer: {
      get() {
        return this.tattooerState;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_TATTOOER", value);
      },
    },
    customer: {
      get() {
        return this.customerState;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_CUSTOMER", value);
      },
    },
    n_customers: {
      get() {
        return this.customer.extra_customers.length + 1;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_EXTRA_CUSTOMERS", value - 1);
      },
    },
    boxes_obligatoris() {
      if (this.$store.getters["auth/isLoggedIn"] && false) {
        let s = this.customer.studio.user.setting.find(
          (x) => x.key === "box_active"
        );
        if (s && s.value === "1") {
          let s2 = this.customer.studio.user.setting.find(
            (x) => x.key === "boxes_obligatoris"
          );
          if (s2) {
            return s2.value === "1";
          }
        }
      }
      return false;
    },
  },
};
</script>

<style lang="sass" scoped>
.selected
  border: 1px solid var(--v-primary-base)

.upload
  min-height: 100px
  border-radius: 10px
  border: solid var(--v-primary-base) 1px
  text-align: center
.message
  padding-top: 25px
</style>

<style lang="sass">
#piercing-info
  .v-textarea fieldset
    height: 104px
</style>